import React, { useState, useEffect, useRef } from 'react'
import { Button, Box, Select, FormField, Form, Layer, Text, Card, CardBody, CardHeader, CardFooter } from 'grommet'
import { FormClose } from 'grommet-icons'

import { getAllMonthsAndYears, combineMonths, yearsSince } from '../helpers'

const BoardFilter = ({ firestore, filterValues, collection }) => {
  const [countries, setCountries] = useState([])
  const [displayCountries, setDisplayCountries] = useState([])
  const [temples, setTemples] = useState([])
  const [displayTemples, setDisplayTemples] = useState([])
  const [hkcenters, setHKCenters] = useState([])
  const [displayHKCenters, setDisplayHKCenters] = useState([])

  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const [displaySMonths, setDisplaySMonths] = useState(months)
  const [displayEMonths, setDisplayEMonths] = useState(months)
  const formRef = useRef()
  const [filters, setFilters] = useState(false)
  const [filtersCount, setFiltersCount] = useState(0)
  const [displayFilters, setDisplayFilters] = useState({})

  useEffect(() => {
    let isCancelled = false

    firestore.collection('countries').get().then(countries => {
      if (!isCancelled) {
        setCountries(countries.docs.map(snap => snap.id))
        setDisplayCountries(countries.docs.map(snap => snap.id))
      }
    })

    firestore.collection('temples').get().then(temples => {
      temples = temples.docs.map(snap => {
        return { name: snap.id, country: snap.data().country }
      })
      setTemples(temples)
      setDisplayTemples(temples)
    })

    firestore.collection('hkcenters').get().then(hkcenters => {
      hkcenters = hkcenters.docs.map(snap => {
        return { name: snap.id, temple: snap.data().temple, country: snap.data().country }
      })
      setHKCenters(hkcenters)
      setDisplayHKCenters(hkcenters)
    })

    const wskirtanFilters = localStorage.getItem('__WSKIRTAN_FILTERS')
    if (wskirtanFilters && JSON.parse(wskirtanFilters)) {
      filterFetch({ value: JSON.parse(wskirtanFilters) })
    } else {
      const currentDate = new Date();
      filterFetch({ value: {
        syear: currentDate.getFullYear(), smonth: months[currentDate.getMonth()],
        eyear: currentDate.getFullYear(), emonth: months[currentDate.getMonth()]
      } });
    }

    return () => {
      isCancelled = true
    }

  }, [collection])

  const updateStorage = (value) => {
    localStorage.setItem('__WSKIRTAN_FILTERS', JSON.stringify(value))
  }

  const filterFetch = async ({ value }) => {
    updateStorage(value)
    setDisplayFilters({...value})
    const dF = Object.keys(value);
    let calcFiltersCount = 2;
    dF.filter(f => value[f]).forEach((f) => {
      if (['smonth', 'syear', 'emonth', 'eyear', 'count'].includes(f)) {
        return;
      }

      if (collection === 'countries') {
        return
      }
      if (collection === 'temples' && !['country'].includes(f)) {
        return
      }
      if (collection === 'hkcenters' && !['country', 'temple'].includes(f)) {
        return
      }
      calcFiltersCount += 1
    })

    setFiltersCount(calcFiltersCount);

    let startYear = new Date().getFullYear();
    let startMonth = new Date().getMonth();
    let endYear = new Date().getFullYear();
    let endMonth = new Date().getMonth();
    if (value.syear) {
      startYear = value.syear;
    }
    if (value.smonth) {
      startMonth = months.indexOf(value.smonth);
    }
    if (value.eyear) {
      endYear = value.eyear;
    }
    if (value.emonth) {
      endMonth = months.indexOf(value.emonth);
    }

    setDisplayFilters({...value, ...{
      smonth: months[startMonth],
      syear: startYear,
      emonth: months[endMonth],
      eyear: endYear,
    }})

    const fmonths = getAllMonthsAndYears(
      new Date(`${startYear}-${startMonth + 1}-1`),
      new Date(`${endYear}-${endMonth + 1}-1`)
    );

    const fdate = combineMonths(fmonths);
    filterValues(value.country, value.temple, value.hkcenter, value.count, fdate)
    setFilters(false)
  }

  const resetForm = () => {
    formRef.current.reset()
    filterFetch({value: {}})
  }

  return (<>
    <Box direction='row' justify='between' align='center' gap='large' margin={{ bottom: 'medium' }}>
      <Box direction='row' justify='between' align='center' gap='large' margin={{ bottom: 'medium' }}>
        <Button badge={!filtersCount ? false : { background: { color: 'brand' }, value: filtersCount }}
            label={(!filters ? 'Show' : 'Hide') + ' filters'}
            size='small' margin={{ bottom: 'none' }} onClick={() => setFilters(!filters)} />

        {displayFilters && (<Text size={'small'} background='light-1'>
          {['hkcenter', 'temple', 'country'].map((dF, index) => {
            const disF = (dF === 'hkcenter') ? 'Center' : dF.charAt(0).toUpperCase() + dF.slice(1);

            if (collection === 'countries') {
              return
            }
            if (collection === 'temples' && !['country'].includes(dF)) {
              return
            }
            if (collection === 'hkcenters' && !['country', 'temple'].includes(dF)) {
              return
            }

            if (displayFilters[dF]) {
              return `${disF} - ${displayFilters[dF]}`
            }

          }).filter(x => !!x).join(', ')}
        </Text>)}      
      </Box>

      <Box direction='row' justify='between' gap='large' margin={{ bottom: 'medium' }}>
        {(displayFilters.syear === displayFilters.eyear) && (displayFilters.smonth === displayFilters.emonth) && <Text size={'small'} background='light-1'>
          {displayFilters.emonth}, {displayFilters.eyear}
        </Text>}

        {(displayFilters.syear === displayFilters.eyear) && (displayFilters.smonth !== displayFilters.emonth) && <Text size={'small'} background='light-1'>
          {displayFilters.smonth} - {displayFilters.emonth}, {displayFilters.eyear}
        </Text>}

        {(displayFilters.syear !== displayFilters.eyear) && (displayFilters.smonth !== displayFilters.emonth) && <Text size={'small'} background='light-1'>
          {displayFilters.smonth}, {displayFilters.syear} - {displayFilters.emonth}, {displayFilters.eyear}
        </Text>}
      </Box>
    </Box>
    {filters && <Layer 
      onEsc={() => setFilters(false)} 
      onClickOutside={() => setFilters(false)}
      background={{ color: 'light-1', opacity: 1 }}
      full={true}
    >
      <Box
        fill
        align='center'
        direction='column'
        overflow={{ vertical: 'auto' }}
        pad={{ vertical: 'large', horizontal: 'large' }}
      >
      <Card background='light-1' width='large' margin={{ vertical: 'large' }}>
        <CardHeader pad='medium' background={'light-2'}>
          <Text weight={'bold'}>Add filters</Text>
          <Button icon={<FormClose />} onClick={() => setFilters(false)} />
        </CardHeader>
        <Form onSubmit={filterFetch} validate='submit' ref={formRef} style={{ overflowY: 'auto' }}>
          <CardBody pad={'small'}>
            <Box direction='column' flex>
              {['scores', 'score_subs', 'teams', 'team_subs', 'hkcenters', 'temples'].includes(collection) && <FormField name='country' htmlFor='country' margin={{ horizontal: 'small', vertical: 'small' }} label='Country'>
                <Select
                  id='country' name='country' placeholder="Select Country"
                  options={displayCountries.concat('Others').filter((value,index,self) => self.indexOf(value) === index)}
                  value={displayFilters && displayFilters.country}
                  onChange={({ option }) => {
                    displayFilters.country = option
                    setDisplayFilters({...displayFilters})
                    if (!option) {
                      setDisplayTemples(temples)  
                    } else {
                      setDisplayTemples(temples.filter(temple => option === temple.country))
                    }
                  }}
                  onSearch={key => {
                    key = key.trim()
                    if (!key) {
                      setDisplayCountries(countries)
                    }
                    setDisplayCountries(countries.filter(country => country.toUpperCase().match(key.toUpperCase())))
                  }}
                  onClose={() => setDisplayCountries(countries)}
                  clear={{ label: 'Clear' }}
                />
              </FormField>}

              {['scores', 'score_subs', 'teams', 'team_subs', 'hkcenters'].includes(collection) && <FormField name='temple' htmlFor='temple' margin={{ horizontal: 'small', vertical: 'small' }} label='Temple'>
                <Select
                  id='temple' name='temple' placeholder="Select Temple"
                  options={displayTemples.map(temple => temple.name).concat('Others').filter((value,index,self) => self.indexOf(value) === index)}
                  value={displayFilters && displayFilters.temple}
                  onChange={({ option }) => {
                    displayFilters.temple = option
                    setDisplayFilters({...displayFilters})
                    if (!option) {
                      setDisplayHKCenters(hkcenters)
                    } else {
                      setDisplayHKCenters(hkcenters.filter(hkcenter => option === hkcenter.temple))
                    }
                  }}
                  onSearch={key => {
                    key = key.trim()
                    if (!key) {
                      setDisplayTemples(temples)
                    }
                    setDisplayTemples(temples.filter(temple => temple.name.toUpperCase().match(key.toUpperCase())))
                  }}
                  clear={{ label: 'Clear' }}
                />
              </FormField>}

              {(['scores', 'score_subs', 'teams', 'team_subs'].includes(collection)) && <FormField name='hkcenter' htmlFor='hkcenter' margin={{ horizontal: 'small', vertical: 'small' }} label='Center'>
                <Select
                  id='hkcenter' name='hkcenter' placeholder="Select Center"
                  options={displayHKCenters.map(hkcenter => hkcenter.name).concat('Others').filter((value,index,self) => self.indexOf(value) === index)}
                  value={displayFilters && displayFilters.hkcenter}
                  onSearch={key => {
                    key = key.trim()
                    if (!key) {
                      setDisplayHKCenters(hkcenters.map(hkcenter => hkcenter.name))
                    }
                    setDisplayHKCenters(hkcenters.filter(hkcenter => hkcenter.name.toUpperCase().match(key.toUpperCase())))
                  }}
                  clear={{ label: 'Clear' }}
                  onChange={({ option }) => {
                    displayFilters.hkcenter = option
                    setDisplayFilters({...displayFilters})
                  }}
                />
              </FormField>}

              {/* {(['scores', 'score_subs', 'teams', 'team_subs'].includes(collection)) && <FormField name='count' htmlFor='count' margin={{ horizontal: 'small', vertical: 'small' }} label='Count'>
                <Select
                  id='count' name='count' placeholder="Select count"
                  options={[2, 20, 50, 100, 200, 500]}
                  value={displayFilters && displayFilters.count}
                  clear={{ label: 'Clear' }}
                  onChange={({ option }) => {
                    displayFilters.count = option
                    setDisplayFilters({...displayFilters})
                  }}
                />
              </FormField>} */}

              {/* <FormField name='month' htmlFor='month' margin={{ horizontal: 'small', vertical: 'small' }} label='Month'>
                <Select
                  id='month' name='month' placeholder="Select month"
                  options={displayMonths}
                  value={displayFilters && displayFilters.month}
                  onSearch={key => {
                    key = key.trim()
                    if (!key) {
                      setDisplayMonths(months)
                    }
                    setDisplayMonths(months.filter(month => month.toUpperCase().match(key.toUpperCase())))
                  }}
                  onClose={() => setDisplayCountries(countries)}
                  clear={{ label: 'Clear' }}
                  onChange={({ option }) => {
                    displayFilters.month = option
                    setDisplayFilters({...displayFilters})
                  }}
                />
              </FormField>

              <FormField name='year' htmlFor='year' margin={{ horizontal: 'small', vertical: 'small' }} label='Year'>
                <Select
                  id='year' name='year' placeholder="Select year"
                  options={yearsSince(2022)}
                  value={displayFilters && displayFilters.year}
                  clear={{ label: 'Clear' }}
                  onChange={({ option }) => {
                    displayFilters.year = option
                    setDisplayFilters({...displayFilters})
                  }}
                />
              </FormField> */}

              <Box direction='row' gap='xsmall' justify='start'>
                <FormField name='smonth' htmlFor='smonth' margin={{ horizontal: 'small', vertical: 'small' }} label='Start month'>
                  <Select
                    id='smonth' name='smonth' placeholder="Select month"
                    options={displaySMonths}
                    value={displayFilters && displayFilters.smonth}
                    onSearch={key => {
                      key = key.trim()
                      if (!key) {
                        setDisplaySMonths(months)
                      }
                      setDisplaySMonths(months.filter(month => month.toUpperCase().match(key.toUpperCase())))
                    }}
                    clear={{ label: 'Clear' }}
                    onChange={({ option }) => {
                      displayFilters.smonth = option
                      setDisplayFilters({...displayFilters})
                    }}
                  />
                </FormField>
                <FormField name='syear' htmlFor='syear' margin={{ horizontal: 'small', vertical: 'small' }} label='Start year'>
                  <Select
                    id='syear' name='syear' placeholder="Select year"
                    options={yearsSince(2022)}
                    value={displayFilters && displayFilters.syear}
                    clear={{ label: 'Clear' }}
                    onChange={({ option }) => {
                      displayFilters.syear = option
                      setDisplayFilters({...displayFilters})
                    }}
                  />
                </FormField>
              </Box>

              <Box direction='row' gap='xsmall' justify='start'>
                <FormField name='emonth' htmlFor='emonth' margin={{ horizontal: 'small', vertical: 'small' }} label='End month'>
                  <Select
                    id='emonth' name='emonth' placeholder="Select month"
                    options={displayEMonths}
                    value={displayFilters && displayFilters.emonth}
                    onSearch={key => {
                      key = key.trim()
                      if (!key) {
                        setDisplayEMonths(months)
                      }
                      setDisplayEMonths(months.filter(month => month.toUpperCase().match(key.toUpperCase())))
                    }}
                    clear={{ label: 'Clear' }}
                    onChange={({ option }) => {
                      displayFilters.emonth = option
                      setDisplayFilters({...displayFilters})
                    }}
                  />
                </FormField>
                <FormField name='eyear' htmlFor='eyear' margin={{ horizontal: 'small', vertical: 'small' }} label='End year'>
                  <Select
                    id='eyear' name='eyear' placeholder="Select year"
                    options={yearsSince(2022)}
                    value={displayFilters && displayFilters.eyear}
                    clear={{ label: 'Clear' }}
                    onChange={({ option }) => {
                      displayFilters.eyear = option
                      setDisplayFilters({...displayFilters})
                    }}
                  />
                </FormField>
              </Box>

            </Box>
          </CardBody>
          <CardFooter background={'light-2'} pad='small'>
            <Box direction='row' gap='medium' pad='small'>
              <Button primary type='submit' size='small' label='Fetch data' />
              <Button size='small' label='Clear' onClick={resetForm} />
            </Box>
            {/* <Button icon={<FormClose />} onClick={() => setFilters(false)} /> */}
          </CardFooter>
        </Form>
    </Card>
    </Box>
    </Layer>}
  </>)

}

export default BoardFilter