import { useEffect, useState } from 'react';
import { Avatar, Form, FormField, TextInput, Button, Box, Card, CardBody, CardFooter, CardHeader, Anchor, Select, Text } from 'grommet';

import firebase from 'firebase/compat/app';

import Notify from './Notify';
import { Logout } from 'grommet-icons';

const Me = ({ user, firestore, signOut }) => {
  const profileRef = user && user.uid && firestore.collection('profiles').doc(user.uid);

  const [profileName, setProfileName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [country, setCountry] = useState('');
  const [temple, setTemple] = useState('');
  const [hkcenter, setHKCenter] = useState('');
  const [submitDisable, setSubmitDisable] = useState(false);
  const [prevTeam, setPrevTeam] = useState();
  const [isAdmin, setIsAdmin] = useState();

  const [countries, setCountries] = useState([]);
  const [displayCountries, setDisplayCountries] = useState([]);
  const [temples, setTemples] = useState([]);
  const [displayTemples, setDisplayTemples] = useState([]);
  const [hkcenters, setHKCenters] = useState([]);
  const [displayHKCenters, setDisplayHKCenters] = useState([]);

  const [notify, setNotify] = useState(false);
  const [email, setEmail] = useState(user && user.email);
  const [userPhoto, setUserPhoto] = useState(user && user.photoURL);
  const [desc, setDesc] = useState('');
  const [checked, setChecked] = useState(false);
  const [hasData, setHasData] = useState(true);

  // const tempFn = async () => {
  //   const allProfiles = await firestore.collection('profiles').get()
  //   allProfiles.docs.forEach(async snap => {
  //     const email = snap.data().email;
  //     const phoneNumber = snap.data().phoneNumber;
  //     const privateRef = await firestore.collection('profiles').doc(snap.id).collection('private').doc('info');
  //     // await privateRef.set({ email, phoneNumber });
  //     await snap.ref.update({
  //       phoneNumber: firebase.firestore.FieldValue.delete(),
  //       profileNumber: firebase.firestore.FieldValue.delete(),
  //       email: firebase.firestore.FieldValue.delete()
  //     });
  //   })
  // }

  // window.tempFn = () => {
  //   tempFn();
  // }

  useEffect(() => {
    let isCancelled = false;

    profileRef && profileRef.collection('private').doc('info').get().then((profileinfo) => {
      const profileInfoData = profileinfo.data();
      if (profileInfoData) {
        setPhoneNumber(profileInfoData.phoneNumber);
        profileInfoData.email && setEmail(profileInfoData.email);
      }
    });

    profileRef && profileRef.get().then((profile) => {
      if (!isCancelled) {
        firestore.collection('sankirtana').where('uid', '==', user.uid).get().then((sData) => {
          sData && setHasData(!!sData.size)
        })
      }

      const profileData = profile.data();

      if (profileData) {
        setPrevTeam(profileData.team);
        setProfileName(profileData.profileName);
        setCountry(profileData.country);
        setTemple(profileData.temple);
        setHKCenter(profileData.hkcenter);
        profileData.photoURL && setUserPhoto(profileData.photoURL);
        profileData.team && setChecked(profileData.team);
        profileData.desc && setDesc(profileData.desc);
        profileData.role && setIsAdmin(profileData.role === "admin");
      } else {
        setProfileName(user && user.displayName);
      }
    });

    firestore.collection('countries').get().then(countries => {
      if (!isCancelled) {
        setCountries(countries.docs.map(snap => snap.id))
        setDisplayCountries(countries.docs.map(snap => snap.id))
      }
    })

    firestore.collection('temples').get().then(temples => {
      temples = temples.docs.map(snap => {
        return {name: snap.id, country: snap.data().country}
      })
      setTemples(temples)
      setDisplayTemples(temples)
    })

    firestore.collection('hkcenters').get().then(hkcenters => {
      hkcenters = hkcenters.docs.filter(snap => !snap.data().inactive).map(snap => {
        return {name: snap.id, temple: snap.data().temple, country: snap.data().country}
      })
      setHKCenters(hkcenters)
      setDisplayHKCenters(hkcenters)
    })

    return () => {
      isCancelled = true
    }
  }, [user])

  const updateFlyDates = async() => {
    const flydates = await firestore.collection('flydates')
      .where('searchkey', '==', (prevTeam ? 'teams' : 'scores'))
      .where('searchval', '==', user.uid).get();
    flydates.docs.forEach(async(snap) => {
      let newSnapId = snap.id.replace('scores', 'teams');
      if (prevTeam) {
        newSnapId = snap.id.replace('teams', 'scores');
      }
      const newSnapData = {...snap.data(), searchkey: (prevTeam ? 'scores' : 'teams')};
      // console.log('creating new id:', newSnapId, 'new data:', newSnapData);
      const nFlyRef = firestore.collection('flydates').doc(newSnapId);
      await nFlyRef.set(newSnapData);
      // console.log('deleting', snap.id)
      const oFlyRef = firestore.collection('flydates').doc(snap.id);
      await oFlyRef.delete();
    })
  }

  const updateScoresTeam = async() => {
    const toDelRef = await firestore.collection(prevTeam ? 'teams' : 'scores').doc(user.uid);
    const refData = await toDelRef.get();
    const newSnapData = {...refData.data()};
    const nFlyRef = firestore.collection(prevTeam ? 'scores' : 'teams').doc(user.uid);
    await nFlyRef.set(newSnapData);
    await toDelRef.delete();
  }

  const saveProfile = async(e) => {
    e.preventDefault()
    setSubmitDisable(true)

    if (hasData && (prevTeam !== checked)) {
      // console.log('Team update, prev:', prevTeam, 'new', checked);
      await Promise.all([
        updateFlyDates(),
        updateScoresTeam()
      ])
    }

    try {
      await profileRef.set({
        profileName,
        desc,
        userPhoto,
        country,
        temple,
        hkcenter,
        team: checked,
        updatedAt: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true })

      const profilePrivateRef = profileRef.collection('private').doc('info');
      profilePrivateRef.set({ email, phoneNumber });

      setPrevTeam(checked)
      setNotify({ status: 'normal', title:'Hare Krishna🙏', message: 'Profile was updated' })
      setSubmitDisable(false)
    } catch (error) {
      setNotify({ status: 'critical', title:'Something went wrong', message: 'Try again later, or inform contact@hkmmumbai.org' })
      setSubmitDisable(false)
    }
  }

  return (<>
    {isAdmin && <Card background="light-2" width={'large'} margin={{ horizontal: 'large', top: 'medium' }} pad='medium' align='center'>
      <Anchor href='/approve' size='medium' label='Pending Approval' />
    </Card>}

    <Card background="light-1" width={'large'} margin={{ horizontal: 'large', vertical: 'medium' }}>

      {notify && <Notify title={notify.title} message={notify.message} status={notify.status} handleClose={() => { setNotify(false) }} />}

      <CardHeader pad='medium' background={"light-2"}>
        <Avatar src={userPhoto} />
        <Button icon={<Logout />} size='small' onClick={signOut} tip={{ content: 'Sign out', plain: false }} />
      </CardHeader>
      <Form onSubmit={saveProfile} validate='blur' messages={{required: "Required"}} >
        <CardBody pad="medium" style={{ overflowY: 'auto', overflowX: 'auto' }}>
          <Text textAlign='end' size='small' tag='div'>
            Email: {email}
            {checked && hasData && <Text size='small' weight={'bold'} color={'text-xweak'}> - participating as team</Text>}
          </Text>
          <FormField name='name' htmlFor='name' label='Name' required>
            <TextInput pad='none' id='name' name='name' value={profileName} onChange={(e) => setProfileName(e.target.value)} />
          </FormField>

          <FormField name='desc' htmlFor='desc' label={'Description' + (checked ? ' (Please mention team member\'s name here)' : '')} margin={{top: 'medium'}}>
            <TextInput pad='none' id='desc' name='desc' value={desc} onChange={(e) => setDesc(e.target.value)} />
          </FormField>

          <FormField name='phone' htmlFor='phone' label='Phone (preferably whatsapp no.)' required margin={{top: 'medium'}}
            validate={(fieldData) => {
              if (!/^[+]?\d{10,12}$/.test(fieldData)) {
                return {message: 'Invalid number', status: 'error'}
              }
            }}
          >
            <TextInput id='phone' name='phone' value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
          </FormField>

          <FormField name='country' htmlFor='country' label='Country (where your mentors are)' margin={{top: 'medium'}} required>
            <Select
              id='country' name='country' required
              options={displayCountries.concat({ country: 'Others'}).filter((value,index,self) => self.indexOf(value) === index)}
              value={country}
              onChange={({ option }) => {
                setDisplayTemples(temples.filter(temple => temple.country === option))
                setDisplayHKCenters(hkcenters.filter(hkcenter => hkcenter.country === option))
                setCountry(option)
                setTemple('')
                setHKCenter('')
              }}
              onSearch={key => {
                key = key.trim()
                if (!key) {
                  setDisplayCountries(countries)
                }
                setDisplayCountries(countries.filter(country => country.toUpperCase().match(key.toUpperCase())))
              }}
              clear={{ label: 'Clear' }}
            />
          </FormField>

          <FormField name='temple' htmlFor='temple' label='Temple (where your mentors are)' margin={{top: 'medium'}} required>
            <Select
              id='temple' name='temple' required
              options={displayTemples.map(temple => temple.name).concat('Others').filter((value,index,self) => self.indexOf(value) === index)}
              value={temple}
              onChange={({ option }) => {
                setDisplayHKCenters(hkcenters.filter(hkcenter => hkcenter.temple === option))
                setTemple(option)
                setHKCenter('')
              }}
              onSearch={key => {
                key = key.trim()
                if (!key) {
                  setDisplayTemples(temples)
                }
                setDisplayTemples(temples.filter(temple => temple.name.toUpperCase().match(key.toUpperCase())))
              }}
              clear={{ label: 'Clear' }}
            />
          </FormField>

          <FormField name='hkcenter' htmlFor='hkcenter' label='Center (where your mentors are)' margin={{top: 'medium'}} required>
            <Select
              id='hkcenter' name='hkcenter' required
              options={displayHKCenters.map(hkcenter => hkcenter.name).concat('Others').filter((value,index,self) => self.indexOf(value) === index)}
              value={hkcenter}
              onChange={({ option }) => setHKCenter(option)}
              onSearch={key => {
                key = key.trim()
                if (!key) {
                  setDisplayHKCenters(hkcenters)
                }
                setDisplayHKCenters(hkcenters.filter(hkcenter => hkcenter.name.toUpperCase().match(key.toUpperCase())))
              }}
              clear={{ label: 'Clear' }}
            />
          </FormField>

          <FormField name='checked' htmlFor='checked' label={"Participating as? " + (hasData ? '(Caution: Changing this will affect your ranking)' : '')} margin={{top: 'medium', bottom: 'medium'}} required>
            <Select
              id='checked' name='checked' required
              options={['Team', 'Individual']}
              value={checked ? 'Team' : 'Individual'}
              onChange={({ option }) => setChecked(option === 'Team')}
            />
          </FormField>

        </CardBody>
        <CardFooter pad='medium' background="light-2" style={{ zIndex: '1' }}>
        <Box flex direction='row' align='center' justify='between'>
            <Text></Text>
            <Button primary disabled={submitDisable} type="submit" size='medium' label="Update profile" />
          </Box>
        </CardFooter>
      </Form>
    </Card>
    <Card background="light-2" width={'large'} margin={{ horizontal: 'large', bottom: 'medium' }} pad='medium' align='center'>
      <Anchor href='/sankirtana' size='medium' label='View your Sankirtana data' />
    </Card>
  </>)
}

export default Me