import { Notification } from 'grommet'

const Notify = ({ status, title, message, handleClose }) => {
  return(
    <Notification
      toast={{position: 'bottom-left'}}
      status={status}
      title={title}
      message={message}
      onClose={handleClose} />
  )
}

export default Notify