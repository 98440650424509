import { Card, CardHeader, CardBody, CardFooter, Text, Spinner } from 'grommet'

import { useRef } from 'react';

import {
  Chart as ChartJS,
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import { useEffect } from 'react';

ChartJS.register(
  LinearScale,
  CategoryScale,
  BarElement,
  PointElement,
  LineElement,
  Legend,
  Tooltip
);

const ProfileGraph = ({ dashData }) => {
  const chartRef = useRef(null)

  if (!dashData) {
    return (<CardBody><Spinner /></CardBody>)
  }

  let data = dashData.map(d => {
    return {
      createdAt: d.createdAt,
      date: d.createdAt.toDate().toLocaleDateString('en-US'),
      score: parseFloat(d.score),
      categories: d.categories
    }
  }).sort((x, y) => x.createdAt - y.createdAt).reduce((n,o) => {
    const numBooks = Object.keys(o['categories']).reduce((x, y) => {
      return x + o['categories'][y]
    }, 0)
    if (n[o['date']]) {
      n[o['date']] = {
        score: parseFloat(n[o['date']]['score'] + o['score']),
        numBook: n[o['date']]['numBook'] + (parseInt(numBooks) || 0),
        small: n[o['date']]['small'] + (o['categories']['small'] || 0),
        medium: n[o['date']]['medium'] + (o['categories']['medium'] || 0),
        big: n[o['date']]['big'] + (o['categories']['big'] || 0),
        m_big: n[o['date']]['m_big'] + (o['categories']['m-big'] || 0),
      }
    } else {
      n[o['date']] = {
        score: parseFloat(o['score']) || 0,
        numBook: parseInt(numBooks) || 0,
        small: (o['categories']['small'] || 0),
        medium: (o['categories']['medium'] || 0),
        big: (o['categories']['big'] || 0),
        m_big: (o['categories']['m-big'] || 0),
      }
    }
    return n
  }, {})

  const emptyValues = { big: 0, m_big: 0, medium: 0, numBook: 0, score: 0, small: 0 }

  const getDates = (startDate, stopDate) => {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
      dateArray.push(new Date(currentDate));
      currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dateArray;
  }

  let nData = {}

  if (data && Object.keys(data)) {
    const dates = getDates(new Date(Object.keys(data)[0]), new Date())
    nData = dates.reduce((x, y) => {
      x[y.toLocaleDateString('en-US')] = data[y.toLocaleDateString('en-US')] || emptyValues
      return x
    }, {})
  }

  // const graphData = Object.keys(nData).map(key => {
  //   return { 'date': key, 'score': nData[key]['score'], 'numBook': nData[key]['numBook'],
  //     'small': nData[key]['small'], 'medium': nData[key]['medium'],
  //     'big': nData[key]['big'], 'm_big': nData[key]['m_big'],
  //   }
  // })

  const chartjsData = {
    labels: Object.keys(nData),
    datasets: [
      // {
      //   type: 'line',
      //   label: 'Score',
      //   borderColor: '#FFB30D',
      //   borderWidth: 1,
      //   data: Object.keys(nData).map(key => nData[key]['score']),
      // },
      {
        type: 'bar',
        label: 'Score',
        backgroundColor: '#FFB30D',
        borderWidth: 0,
        data: Object.keys(nData).map(key => nData[key]['score']),
      },
      {
        type: 'bar',
        label: 'No. of books',
        backgroundColor: '#001542',
        borderWidth: 0,
        data: Object.keys(nData).map(key => nData[key]['numBook']),
      },
    ]
  }

  return(
    <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', bottom: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Text weight='bold'>Profile Graph</Text>
      </CardHeader>
      <CardBody pad='medium' size='large' overflow={{ horizontal: 'auto' }}>
        {/* <DataChart data={graphData}
          series={[
            { label: "Date", property: "date" },
            { label: "Score", property: "score" },
            { label: "# Books", property: "numBook" },
            { label: "# Small Books", property: "small" },
            { label: "# Medium Books", property: "medium" },
            { label: "# Big Books", property: "big" },
            { label: "# M-Big Books", property: "m_big" }
          ]}
          chart={[
            // { property: 'score', type: 'point', thickness: 'small', point: 'circle' },
            // { property: 'score', type: 'line', thickness: 'hair' },
            { property: 'score', type: 'bar', color: 'brand', opacity: 'strong', thickness: 'small' },
            { property: 'numBook', type: 'point', thickness: 'small', point: 'circle' },
            { property: 'numBook', type: 'line', thickness: 'hair' },
            { property: 'small', type: 'point', thickness: 'xsmall', point: 'circle' },
            { property: 'small', type: 'line', thickness: 'hair', dash: true },
            { property: 'medium', type: 'point', thickness: 'xsmall', point: 'circle' },
            { property: 'medium', type: 'line', thickness: 'hair', dash: true },
            { property: 'big', type: 'point', thickness: 'xsmall', point: 'circle' },
            { property: 'big', type: 'line', thickness: 'hair', dash: true },
            { property: 'm_big', type: 'point', thickness: 'xsmall', point: 'circle' },
            { property: 'm_big', type: 'line', thickness: 'hair', dash: true },
          ]}
          guide={{ x: { granularity: 'fine' }, y : { granularity: 'fine' } }} 
          detail={true}
          gap={'small'}
          size={{ width: 'fill', height: '400px' }}
          axis={{ x: { granularity: 'fine', property: 'date' }, y : { granularity: 'medium', property: 'score' } }}
        /> */}

        <Chart ref={chartRef} type='bar' data={chartjsData} />
      </CardBody>
      <CardFooter pad='medium' background='light-2'>
      </CardFooter>
    </Card>
  )
}

export default ProfileGraph