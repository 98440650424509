import React, { useEffect, useState } from 'react';
import { Box, Spinner, Card, CardBody, CardFooter, CardHeader, Text, Table, TableBody, TableHeader, TableRow, TableCell, Button, Tag, Avatar } from 'grommet';
import { Validate } from 'grommet-icons';
import { useParams, useNavigate } from 'react-router-dom';
import ProfileGraph from './ProfileGraph';

const Profile = ({ firestore }) => {
  const { uid } = useParams()

  const [dashData, setDashData] = useState()
  const [profileName, setProfileName] = useState('')
  const [temple, setTemple] = useState('')
  const [hkcenter, setHKCenter] = useState('')
  const [score, setScore] = useState()
  const [subScore, setSubScore] = useState()
  const [desc, setDesc] = useState()
  const [userPhoto, setUserPhoto] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    let isCancelled = false

    const fetchProfileData = async () => {
      if (isCancelled) {
        return
      }

      const sankirtanData = await firestore.collection('sankirtana').where('uid', '==', uid).where('status', 'in', ["unapproved", "approved"]).get()
      const dData = sankirtanData.docs.map(snap => snap.data()).sort((x, y) => y.createdAt - x.createdAt)
      
      if (isCancelled) {
        return
      }

      setDashData(dData)
      // setScore(dData.reduce((x, y) => {
      //   return x + parseFloat(y.score)
      // }, 0))

      const profileRef = await firestore.collection('profiles').doc(uid)
      profileRef.get().then((profile) => {
        if (!isCancelled) {
          const profileData = profile.data()
          if (!profileData) {
            navigate('/')
          } else {
            setUserPhoto(profileData.userPhoto)
            setProfileName(profileData.profileName)
            setDesc(profileData.desc)
            setTemple(profileData.temple)
            setHKCenter(profileData.hkcenter)

            const scRef1 = firestore.collection(profileData.team ? 'teams' : 'scores').doc(uid);
            scRef1 && scRef1.get().then((d) => {
              setScore(d.data() ? d.data().score : 0)
            })

            const scRef2 = firestore.collection(profileData.team ? 'team_subs' : 'score_subs').doc(uid);
            scRef2 && scRef2.get().then((d) => {
              setSubScore(d.data() ? d.data().score : 0)
            })
          }
        }
      })
    }

    fetchProfileData()

    return () => {
      isCancelled = true
    }

  }, [])
  
  return (<>
    <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Box flex justify='between' direction='row' align='center'>
          <Box flex direction='row'>
            <Avatar src={userPhoto} />
            <Box margin={{ left: 'small' }}>
              <Text weight='bold'>{profileName} <br />
              {desc && <><Text color={'text-xweak'} size='xsmall'>{desc}</Text><br /></>}
              <Text color={'text-xweak'} size='xsmall'>{hkcenter}, {temple}</Text></Text>
            </Box>
          </Box>
          <Box gap={"small"}>
            {score ? <Text weight='bold' align='end' textAlign='end'>Reg. Score: {score.toFixed(2)}</Text> : ''}
            {subScore ? <Text weight='bold' align='end' textAlign='end'>Sub. Score: {subScore.toFixed(2)}</Text> : ''}
          </Box>
        </Box>
      </CardHeader>
      <CardBody pad='medium' overflow={{ horizontal: 'auto' }}>
        {!dashData && <Spinner />}
        {dashData && (dashData.length === 0) && <Text>No data</Text>}
        {dashData && (dashData.length > 0) && <Table>
          <TableHeader>
            <TableRow>
              <TableCell scope='col' border='bottom'>Date</TableCell>
              <TableCell scope='col' border='bottom'>Books</TableCell>
              <TableCell scope='col' border='bottom' align='center'>Language</TableCell>
              <TableCell scope='col' border='bottom' align='right'>Score</TableCell>
            </TableRow>
          </TableHeader>
          <TableBody>
            {dashData && dashData.map((data, index) => {
              return(
                <TableRow key={index} style={{ background: ((index % 2) ? '#F2F2F2' : '') }}>
                  <TableCell>
                    <Box flex direction='row' align='center' justify='start' gap='small'>
                      {data.status === "approved" ? <Validate color='status-ok' /> : <Validate color='status-disabled' />}
                      {data.createdAt ? String(data.createdAt.toDate().toLocaleDateString("en-UK")) : '-'}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Box direction='row' gap='xsmall' wrap={true}>
                      {Object.keys(data.booksObject).map((bN, index) => (
                        <Tag key={index} name={bN}
                          value={data.booksObject[bN]}
                          size={'small'}
                          as={'span'}
                          background={'light-1'}
                          margin={{ vertical: 'xxsmall' }}
                        />
                      ))}
                      {data.subsidized && <Button primary label={"Subsidized"} size='small' color="accent-2" margin={{ vertical: 'xxsmall' }} />}
                    </Box>
                  </TableCell>
                  <TableCell align='center'>{data.bookLang}</TableCell>
                  <TableCell align='right'>{parseFloat(data.score).toFixed(2)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>}
      </CardBody>

      <CardFooter pad='medium' background='light-2'>
        <Text></Text>
        <Button primary size='medium' href='/sankirtana' label='Add your score' />
      </CardFooter>
    </Card>
    <ProfileGraph dashData={dashData} />
  </>)
}

export default Profile