import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Box, Spinner, Card, CardBody, CardFooter, CardHeader, Text, Table, TableBody, TableHeader, TableRow, TableCell, Tag, Select } from 'grommet';
import { Group, Trash, StatusGood } from 'grommet-icons';

import firebase from 'firebase/compat/app'

import Notify from './Notify';
import ConfirmBox from './ConfirmBox';

const Approval = ({ user, firestore }) => {

  const [pcountries, setCountries] = useState();
  const [ptemples, setTemples] = useState();
  const [phkcenters, setHKCenters] = useState();

  const [sankirtanaData, setSankirtanaData] = useState();
  const [lastDoc, setLastDoc] = useState();
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [notify, setNotify] = useState(false);
  const [reload, setReload] = useState(false);

  const [center, setCenter] = useState();

  const { uid } = user;

  const [confirmDialog, setConfirmDialog] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const fetchSankirtanaData = async (lastDoc, page_size = 10) => {
    if (!center) {
      return [];
    }
    let sankirtanaRef = firestore.collection('sankirtana');
    sankirtanaRef = sankirtanaRef.where('status', 'in', ["unapproved"]).where('hkcenter', '==', center).orderBy("status", "desc").orderBy('createdAt', 'desc')

    if (lastDoc) {
      sankirtanaRef = sankirtanaRef.startAfter(lastDoc);
    }

    const data = await sankirtanaRef.limit(page_size).get();
    return data.docs;
  };

  useEffect(() => {
    const fn = async  () => {
      setLoading(true);
      const docs = await fetchSankirtanaData();
      setSankirtanaData(docs);
      if (docs.length === 0) {
        setHasMore(false);
        setLastDoc();
      } else {
        setHasMore(true);
        setLastDoc(docs[docs.length - 1]);
      }
      setLoading();
    };

    fn();
  }, [reload]);

  useEffect(() => {
    const profileRef = user && user.uid && firestore.collection('profiles').doc(uid);
    profileRef && profileRef.get().then((profile) => {
      const profileData = profile.data()
      profileData && setCountries(profileData.countries || []);
      profileData && setTemples(profileData.temples || []);
      profileData && setHKCenters(profileData.hkcenters || []);
    })
  }, [])

  const observer = useRef();

  const lastElementRef = useCallback((node) => {
    if (loading) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && hasMore) {

        setLoading(true);

        const docs = await fetchSankirtanaData(lastDoc);

        if (docs.length === 0) {
          setHasMore(false);
          setLastDoc();
        } else {
          setHasMore(true);
          setLastDoc(docs[docs.length - 1]);
          setSankirtanaData(prevSankirtanaData => {
            return [...prevSankirtanaData, ...docs]
          });
        }
        setLoading();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [hasMore, loading]);

  const allowActions = (sData) => {
    const { hkcenter, temple, country } = sData;
    return phkcenters.includes(hkcenter) && ptemples.includes(temple) && pcountries.includes(country);
  }

  const delConfirm = (snapId, keyId) => {
    setConfirmDialog(true)
    setDeleteId(snapId)
  };

  const deleteEntry = async () => {
    const delRef = firestore.collection('sankirtana').doc(deleteId);
    const wsData = await delRef.get();
    const sData = wsData.data();
    if (!allowActions(sData)) {
      setNotify({ status: 'critical', title:'Profile variables mismatch', message: 'You are not allowed to delete this for now.' });
    } else  {
      delRef.set({
        status: "deleted",
        deletedBy: uid,
        deletedAt: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true }).then(() => {
        setNotify({ status: 'critical', title: 'Deleted', message: `An entry from ${sData.profileName} has been removed.` });
        setReload(p => !p);
      })
    }
    setConfirmDialog(false)
  };

  const approveEntry = async (snapId) => {
    const delRef = firestore.collection('sankirtana').doc(snapId);
    const wsData = await delRef.get();
    const sData = wsData.data();
    if (!allowActions(sData)) {
      setNotify({ status: 'critical', title:'Profile variables mismatch', message: 'You are not allowed to approve this for now.' });
    } else  {
      await delRef.set({
        status: "approved",
        approvedBy: uid,
        approvedAt: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true }).then(() => {
        setNotify({ status: 'normal', title: 'Approved', message: `An entry from ${sData.profileName} has been approved.` });
        setReload(p => !p);
      })
    }
  };

  return (
    <>
      {notify && <Notify title={notify.title} message={notify.message} status={notify.status} handleClose={() => { setNotify(false) }} />}
      {confirmDialog && <ConfirmBox label={'Yes, Delete'} handleConfirm={deleteEntry} handleClose={() => setConfirmDialog(false)} />}
      <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', vertical: 'large' }}>
        <CardHeader pad='medium' background={'light-2'}>
          <Box width={"100%"}>
            <Text weight='bold' size='large'>Pending for Approval</Text>
            <Box direction='row' gap="small" justify='between' width={"100%"}>
              <Box direction='row' gap='small' margin={{ vertical: "small" }}>
                <Text weight="bold" size="small" color="graph-3">You can approve for</Text>
                {ptemples && <Text weight="bold" size="small" color="graph-1">{ptemples.join(', ')}</Text>}
                {pcountries && <Text weight="bold" size="small" color="status-ok">{pcountries.join(', ')}</Text>}
              </Box>
              {phkcenters && <Text>
                <Select
                  options={phkcenters}
                  value={center}
                  placeholder="Select Center"
                  size='small'
                  onChange={({ option }) => {
                    setCenter(option);
                    setReload(p => !p);
                  }}
                />
              </Text>}
            </Box>
          </Box>
        </CardHeader>
        <CardBody pad='medium' style={{ overflowY: 'auto', maxHeight: '48vh' }}>
          {sankirtanaData && (sankirtanaData.length === 0) && <Text>No data! Change the center to find some.</Text>}
          {sankirtanaData && (sankirtanaData.length > 0) && <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope='col' border='bottom'>Name</TableCell>
                <TableCell scope='col' border='bottom'>Center</TableCell>
                <TableCell scope='col' border='bottom'>Books</TableCell>
                <TableCell scope='col' border='bottom' align='center'>Language</TableCell>
                <TableCell scope='col' border='bottom' align='center'>Score</TableCell>
                <TableCell scope='col' border='bottom' align='center'>Date</TableCell>
                <TableCell scope='col' border='bottom' align='right'>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sankirtanaData.map((snap, index) => {
                const data = snap.data()
                return(<React.Fragment key={index}>
                  <TableRow style={{ background: ((index % 2) ? '#F2F2F2' : '') }}>
                    <TableCell>
                      <Box flex direction='row' align='center' justify='start' gap='xxsmall'>
                        {data.team ? <Group color='graph-3' /> : <></>}
                        <Tag name={data.profileName} border={false} as="h4" />
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box flex direction='column' align='start' justify='center' gap='xxsmall'>
                        <Text size="xsmall" color="graph-3">{data.hkcenter}</Text>
                        <Text size="xsmall" color="status-warning">{data.temple}</Text>
                        <Text size="xsmall" color="status-ok">{data.country}</Text>
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box wrap={true} flex direction='row' align='center' justify='start' gap='xsmall'>
                        {Object.keys(data.booksObject).map((bN, i) => (
                          <Tag key={`${index}_books_${i}`} name={bN}
                            value={data.booksObject[bN]}
                            size={'small'}
                            as={'span'}
                            background={'light-2'}
                            margin={{ vertical: 'xxsmall' }}
                          />
                        ))}
                        {data.subsidized && <Button primary label={"Subsidized"} size='small' color="accent-2" margin={{ vertical: 'xxsmall' }} />}
                      </Box>
                    </TableCell>
                    <TableCell align='center'>{data.bookLang}</TableCell>
                    <TableCell align='center'>{parseFloat(data.score).toFixed(2)}</TableCell>
                    <TableCell align='center'>
                      {data.createdAt ? String(data.createdAt.toDate().toLocaleDateString("en-UK")) : '-'}
                    </TableCell>
                    <TableCell align='right'>
                      <Box flex direction='row' align='center' justify='end' gap='xsmall'>
                        <Button disabled={!allowActions(data)} icon={<StatusGood color='status-ok'/>} onClick={() => {approveEntry(snap.id)}} />
                        <Button style={{ padding: "0" }} disabled={!allowActions(data)} icon={<Trash color='status-error' />} onClick={() => {delConfirm(snap.id, index)}} />
                      </Box>
                    </TableCell>
                  </TableRow>
                  {(sankirtanaData.length === (index + 1)) && <TableRow ref={lastElementRef} key={"lastElRef"}></TableRow>}
                </React.Fragment>)
              })}
            </TableBody>
          </Table>}
          {loading && <Spinner margin={{ top: 'medium' }}/>}
        </CardBody>

        <CardFooter pad='medium' background='light-2'>
        </CardFooter>
      </Card>
    </>
  )
}

export default Approval