const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const combinedScoresData = (idParam, data) => {
  let seen = {};

  return data.filter(entry => {
    let previous;

    const label = entry[idParam];

    if (seen.hasOwnProperty(label)) {
      previous = seen[label];
      previous['m-big'] += entry['m-big']
      previous['big'] += entry['big']
      previous['small'] += entry['small']
      previous['score'] += entry['score']
      return false;
    }

    seen[label] = entry;

    return true;
  }).sort((x, y) => y.score - x.score);
};

export const getAllMonthsAndYears = (startDate, endDate) => {
  let result = [];
  let current = new Date(startDate);

  while (current <= endDate) {
    const year = current.getFullYear();
    const month = current.getMonth() + 1; // Adding 1 as getMonth() returns zero-based index

    result.push({ year, month });

    // Move to the next month
    current.setMonth(current.getMonth() + 1);
  }

  return result;
};

export const combineMonths = (fmonths) => {
  if (fmonths.length < 12) {
    return fmonths.map(f => `M${f.year}${months[f.month - 1].toUpperCase()}`);
  }

  let yearMap = {};

  fmonths.forEach(f => {
    yearMap[f.year] ||= [];
    if (yearMap[f.year].indexOf(f.month) === -1) {
      yearMap[f.year].push(f.month);
    }
  });

  const fullYears = Object.keys(yearMap).filter(y => yearMap[y].length === 12).map(y => parseInt(y));

  const searchYears = fmonths.filter(f => fullYears.includes(f.year)).map(f => `Y${f.year}`);
  const searchMonths = fmonths.filter(f => !fullYears.includes(f.year)).map(f => `M${f.year}${months[f.month - 1].toUpperCase()}`);

  return [...new Set(searchYears.concat(searchMonths))];
};

export const yearsSince = (year) => {
  const currentYear = new Date().getFullYear();

  let yearArray = [];
  
  while (year <= currentYear) {
    yearArray.push(year);
    year = year + 1;
  }

  return yearArray;
};

export const fileDownload = (payload, fname) => {
  const keys = Object.keys(payload[0]);
  const header = `${keys.join(",")}\r\n`;
  let link = document.createElement('a');
  let data = '';

  payload.forEach(function(doc) {
    keys.forEach(k => {
      data += '"' + doc[k] + '",';
    });

    data += '\r\n';
  });

  const csv = header + data;
  link.href = 'data:text/csv;charset=utf-8,' + escape(csv);
  link.target = '_blank';
  link.download =  `${fname}.csv`;

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
