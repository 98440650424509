import { useNavigate, useLocation } from 'react-router-dom';
import { Menu, Text } from 'grommet';

const ReportNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const itemsArray = [
    { route: '/teams', label: 'Teams' },
    { route: '/scores', label: 'Individuals' },
    { route: '/centers', label: 'Centers' },
    { route: '/temples', label: 'Temples' },
    { route: '/countries', label: 'Countries' }
  ]

  return(
    <Menu
      label={'Reports'}
      items={
        itemsArray.map(({ route, label }) => {
          return {
            label: <Text color={(location.pathname === route) ? 'text-xweak' : 'text-strong'}>{label}</Text>,
            onClick: () => { (location.pathname === route) ? void(0) : navigate(route) }
          }
        })
      }
    />
  )
}

export default ReportNavigation