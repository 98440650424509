import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Button, Box, Spinner, Card, CardBody, CardFooter, CardHeader, Text, Table, TableBody, TableHeader, TableRow, TableCell, Tag } from 'grommet';
import { Trash as DeleteIcon, Validate } from 'grommet-icons';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import firebase from 'firebase/compat/app'

import Notify from './Notify';
import ConfirmBox from './ConfirmBox';

const Sankirtana = ({ user, firestore }) => {
  const [score, setScore] = useState();
  const [subScore, setSubScore] = useState();

  const [pcountry, setCountry] = useState();
  const [ptemple, setTemple] = useState();
  const [phkcenter, setHKCenter] = useState();

  const [sankirtanaData, setSankirtanaData] = useState();
  const [lastDoc, setLastDoc] = useState();
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);

  const [notify, setNotify] = useState(false);

  const { uid } = user;

  const query = firestore.collection('sankirtana').where('uid', '==', uid).where('status', 'in', ["unapproved", "approved"]).orderBy('createdAt', 'desc').limit(1);
  const [d1, l1, e1, s1] = useCollectionData(query);

  const [confirmDialog, setConfirmDialog] = useState(false)
  const [deleteId, setDeleteId] = useState()

  const fetchSankirtanaData = async (lastDoc, page_size = 10) => {
    let sankirtanaRef = firestore.collection('sankirtana')
    sankirtanaRef = sankirtanaRef.where('uid', '==', uid).where('status', 'in', ["unapproved", "approved"]).orderBy('createdAt', 'desc').orderBy('status')

    // sankirtanaRef = sankirtanaRef.where('uid', '==', uid).orderBy('createdAt', 'desc');
    // for (const d of data.docs) {
    //   await firestore.collection('sankirtana').doc(d.id).set({ status: "approved" }, { merge: true });
    // }

    if (lastDoc) {
      sankirtanaRef = sankirtanaRef.startAfter(lastDoc);
    }

    const data = await sankirtanaRef.limit(page_size).get();
    return data.docs;
  };

  useEffect(() => {
    const fn = async  () => {
      setLoading(true);
      const docs = await fetchSankirtanaData();
      setSankirtanaData(docs);
      if (docs.length === 0) {
        setHasMore(false);
        setLastDoc();
      } else {
        setHasMore(true);
        setLastDoc(docs[docs.length - 1]);
      }
      setLoading();
    }

    fn();
  }, [s1]);

  useEffect(() => {
    const profileRef = user && user.uid && firestore.collection('profiles').doc(uid);
    profileRef && profileRef.get().then((profile) => {
      const profileData = profile.data()
      profileData && setCountry(profileData.country);
      profileData && setTemple(profileData.temple);
      profileData && setHKCenter(profileData.hkcenter);

      const scRef1 = firestore.collection(profileData.team ? 'teams' : 'scores').doc(uid);
      scRef1 && scRef1.get().then((d) => {
        setScore(d.data() ? d.data().score : 0)
      })

      const scRef2 = firestore.collection(profileData.team ? 'team_subs' : 'score_subs').doc(uid);
      scRef2 && scRef2.get().then((d) => {
        setSubScore(d.data() ? d.data().score : 0)
      })
    })
  }, [])

  const observer = useRef();

  const lastElementRef = useCallback((node) => {
    if (loading) {
      return;
    }

    if (observer.current) {
      observer.current.disconnect();
    }

    observer.current = new IntersectionObserver(async (entries) => {
      if (entries[0].isIntersecting && hasMore) {

        setLoading(true);

        const docs = await fetchSankirtanaData(lastDoc);

        if (docs.length === 0) {
          setHasMore(false);
          setLastDoc();
        } else {
          setHasMore(true);
          setLastDoc(docs[docs.length - 1]);
          setSankirtanaData(prevSankirtanaData => {
            return [...prevSankirtanaData, ...docs]
          });
        }
        setLoading();
      }
    });

    if (node) {
      observer.current.observe(node);
    }
  }, [hasMore, loading]);

  const delConfirm = (snapId, keyId) => {
    setConfirmDialog(true)
    setDeleteId(snapId)
  };

  const deleteEntry = async () => {
    const delRef = firestore.collection('sankirtana').doc(deleteId);
    const wsData = await delRef.get();
    const { hkcenter, country, temple } = wsData.data();
    if ( (hkcenter !== phkcenter) || (country !== pcountry) || (temple !== ptemple) ) {
      setNotify({ status: 'critical', title:'Profile variables mismatch', message: 'You have changed profile variables (Country, Temple, Center) now.' });
    } else  {
      delRef.set({
        status: "deleted",
        deletedAt: firebase.firestore.FieldValue.serverTimestamp()
      }, { merge: true }).then(() => {
        setNotify({ status: 'critical', title: 'Deleted', message: `An entry has been removed.` });
      });
    }
    setConfirmDialog(false);
  }

  return (
    <>
      {notify && <Notify title={notify.title} message={notify.message} status={notify.status} handleClose={() => { setNotify(false) }} />}
      {confirmDialog && <ConfirmBox label={'Yes, Delete'} handleConfirm={deleteEntry} handleClose={() => setConfirmDialog(false)} />}
      <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', vertical: 'large' }}>
        <CardHeader pad='medium' background={'light-2'}>
          <Text weight='bold' size='large'>Your sankirtana data</Text>
          <Box gap={"small"}>
            {score ? <Text weight='bold' align='end' textAlign='end'>Reg. Score: {score}</Text> : ''}
            {subScore ? <Text weight='bold' align='end' textAlign='end'>Sub. Score: {subScore}</Text> : ''}
          </Box>
        </CardHeader>
        <CardBody pad='medium' style={{ overflowY: 'auto', maxHeight: '48vh' }}>
          {sankirtanaData && (sankirtanaData.length === 0) && <Text>No data! Please add your books from the list below.</Text>}
          {sankirtanaData && (sankirtanaData.length > 0) && <Table>
            <TableHeader>
              <TableRow>
                <TableCell scope='col' border='bottom'>Date</TableCell>
                <TableCell scope='col' border='bottom'>Books</TableCell>
                <TableCell scope='col' border='bottom' align='center'>Language</TableCell>
                <TableCell scope='col' border='bottom' align='center'>Score</TableCell>
                <TableCell scope='col' border='bottom' align='right'>Action</TableCell>
              </TableRow>
            </TableHeader>
            <TableBody>
              {sankirtanaData.map((snap, index) => {
                const data = snap.data()
                return(<React.Fragment key={index}>
                  <TableRow style={{ background: ((index % 2) ? '#F2F2F2' : '') }}>
                    <TableCell>
                      <Box flex direction='row' align='center' justify='start' gap='small'>
                        {data.status === "approved" ? <Validate color='status-ok' /> : <Validate color='status-disabled' />}
                        {data.createdAt ? String(data.createdAt.toDate().toLocaleDateString("en-UK")) : 'Just now'}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box direction='row' gap='xsmall' wrap={true}>
                        {Object.keys(data.booksObject).map((bN, i) => (
                          <Tag key={`${index}_books_${i}`} name={bN}
                            value={data.booksObject[bN]}
                            size={'small'}
                            as={'span'}
                            background={'light-1'}
                            margin={{ vertical: 'xxsmall' }}
                          />
                        ))}
                        {data.subsidized && <Button primary label={"Subsidized"} size='small' color="accent-2" margin={{ vertical: 'xxsmall' }} />}
                      </Box>
                    </TableCell>
                    <TableCell align='center'>{data.bookLang}</TableCell>
                    <TableCell align='center'>{parseFloat(data.score).toFixed(2)}</TableCell>
                    <TableCell align='right'>
                      <DeleteIcon color={((data.hkcenter !== phkcenter) || (data.country !== pcountry) || (data.temple !== ptemple)) ? 'status-disabled' : 'status-error'} onClick={() => {delConfirm(snap.id, index)}} cursor={"pointer"} />
                    </TableCell>
                  </TableRow>
                  {(sankirtanaData.length === (index + 1)) && <TableRow ref={lastElementRef} key={"lastElRef"}></TableRow>}
                </React.Fragment>)
              })}
            </TableBody>
          </Table>}
          {loading && <Spinner margin={{ top: 'medium' }}/>}
        </CardBody>

        <CardFooter pad='medium' background='light-2'>
        </CardFooter>
      </Card>
    </>
  )
}

export default Sankirtana