import { Card, CardHeader, CardBody, CardFooter, Text, Spinner, FormField, Select } from 'grommet'

import { useRef, useState } from 'react';

import {
  Chart as ChartJS,
  registerables,
} from 'chart.js';

import { Chart } from 'react-chartjs-2';
import { useEffect } from 'react';

ChartJS.register(...registerables);

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(x => x.toUpperCase())

const GenericGraph = ({ firestore, fkey, fvalue, searchkey }) => {
  const [dashData, setDashData] = useState()
  const [mode, setMode] = useState('Score')
  const chartRef = useRef(null)

  useEffect(() => {
    let isCancelled = false

    const fetchFlyData = async () => {
      if (isCancelled) {
        return
      }
      const flyData = await firestore.collection('flydates').where('searchkey', '==', searchkey).where(fkey, '==', fvalue).get()

      setDashData(flyData.docs)
    }

    fetchFlyData()

    return () => {
      isCancelled = true
    }
  }, [])

  let cardContent = (<Spinner alignSelf='center' margin={{ vertical: 'large' }} />)

  if (dashData && dashData.length > 0) {
    const data = dashData
      .filter(snap => snap.data().datekey.startsWith('M'))
      .map(snap => {
        const d = snap.data()
        return {
          score: parseFloat(d.score),
          sval: d.searchval,
          numBooks: (d['m-big'] || 0) + (d.big || 0) + (d.medium || 0) + (d.small || 0),
          monthKey: d.datekey.slice(1, 5) + months.indexOf(d.datekey.slice(5)),
          monthDisplay: d.datekey.slice(5) + ', ' + d.datekey.slice(1, 5),
        }
      })
      .sort((x, y) => x.monthKey - y.monthKey).reduce((n, o) => {
        const dkey = o['monthDisplay']
        n[dkey] ||= {}
        if (n[dkey][o['sval']]) {
          n[dkey][o['sval']] = {
            score: parseFloat(n[dkey]['score'] + o['score']),
            numBooks: n[dkey]['numBooks'] + (o['numBooks'] || 0)
          }
        } else {
          n[dkey][o['sval']] = {
            score: parseFloat(o['score']) || 0,
            numBooks: o['numBooks'] || 0
          }
        }
        return n
      }, {})

    const getColor = (index) => {
      const colors = ['#6FFFB0', '#FFB30D', '#001542', '#799be4', '#fd6868', '#8471a1', '#1271a1']
      if (!colors[index]) {
        return colors[Math.floor(Math.random() * colors.length)]
      }
      return colors[index];
    }

    let dateArray = Object.keys(data);
    dateArray = dateArray.sort((x, y) => {
      return new Date(x) - new Date(y)
    })
    const gdata = {}
    dateArray.forEach(dtkey => {
      Object.keys(data[dtkey]).forEach(skey => {
        gdata[skey] ||= new Array(dateArray.length).fill(0);
        const dtKeyIndex = dateArray.indexOf(dtkey);
        gdata[skey][dtKeyIndex] = (data[dtkey][skey])
      })
    });

    const chartjsData = {
      labels: dateArray,
      datasets: Object.keys(gdata).map((gdkey, i) => {
        return {
          type: 'bar',
          label: gdkey,
          backgroundColor: getColor(i),
          borderWidth: 0,
          data: gdata[gdkey].map(x => (mode === 'Score') ? x.score : x.numBooks)
        }
      })
    }
    cardContent = (<Chart ref={chartRef} type='bar' data={chartjsData} />)
  }

  if (dashData && dashData.length == 0) {
    cardContent = (<Text>No data. Try selecting a filter</Text>)
  }

  return(
    <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', bottom: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        <Text weight='bold'>Comparative chart</Text>
        <FormField margin={{ left: 'large' }}>
          <Select
            size='small'
            placeholder='Select mode'
            options={['Score', 'No. of Books']}
            value={mode}
            onChange={({ option }) => setMode(option)}
          />
        </FormField>
      </CardHeader>
      <CardBody pad='medium' size='large' overflow={{ horizontal: 'auto' }}>
        {cardContent}
      </CardBody>
      <CardFooter pad='medium' background='light-2'>
      </CardFooter>
    </Card>
  )
}

export default GenericGraph