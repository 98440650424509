import { Card, CardHeader, CardBody, CardFooter, Text, Spinner, Box } from 'grommet'

import { useRef, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'

import {
  Chart as ChartJS,
  registerables
} from 'chart.js';

import { Chart } from 'react-chartjs-2';

ChartJS.register(...registerables);

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'].map(x => x.toUpperCase())

const ProfileGraph = ({ firestore }) => {
  const { key, value } = useParams()
  const [dashData, setDashData] = useState()
  const [profile, setProfile] = useState()

  const chartRef = useRef(null)

  useEffect(() => {
    let isCancelled = false

    const fetchFlyData = async () => {
      if (isCancelled) {
        return
      }
      const flyData = await firestore.collection('flydates').where('searchkey', '==', key).where('searchval', '==', value).get()

      if (['scores', 'teams'].includes(key)) {
        const profile = await firestore.collection('profiles').doc(value).get('profileName')
        setProfile(profile.data())
      }

      setDashData(flyData.docs)
    }

    fetchFlyData()

    return () => {
      isCancelled = true
    }
  }, [])

  if (!dashData) {
    return (<CardBody><Spinner /></CardBody>)
  }

  if (dashData.length < 1) {
    return (<CardBody><Text weight='bold' margin={'large'}>Nothing found</Text></CardBody>)
  }

  const data = dashData
    .filter(snap => snap.data().datekey.startsWith('M'))
    .map(snap => {
      const d = snap.data()
      return {
        score: parseFloat(d.score),
        numBooks: (d['m-big'] || 0) + (d.big || 0) + (d.medium || 0) + (d.small || 0),
        monthKey: d.datekey.slice(1, 5) + months.indexOf(d.datekey.slice(5)),
        monthDisplay: d.datekey.slice(5) + ', ' + d.datekey.slice(1, 5),
      }
    })
    .sort((x, y) => x.monthKey - y.monthKey).reduce((n, o) => {
      if (n[o['monthDisplay']]) {
        n[o['monthDisplay']] = {
          score: parseFloat(n[o['monthDisplay']]['score'] + o['score']),
          numBooks: n[o['monthDisplay']]['numBooks'] + (o['numBooks'] || 0)
        }
      } else {
        n[o['monthDisplay']] = {
          score: parseFloat(o['score']) || 0,
          numBooks: o['numBooks'] || 0
        }
      }
      return n
    }, {})

  const sortedDataKeys = Object.keys(data).sort((x, y) => {
    return new Date(x) - new Date(y)
  })

  const chartjsData = {
    labels: sortedDataKeys,
    datasets: [
      {
        type: 'bar',
        label: 'Score',
        backgroundColor: '#FFB30D',
        borderWidth: 0,
        data: sortedDataKeys.map(key => data[key]['score']),
      },
      {
        type: 'bar',
        label: 'No. of books',
        backgroundColor: '#001542',
        borderWidth: 0,
        data: sortedDataKeys.map(key => data[key]['numBooks']),
      },
    ]
  }

  const capitalize = (s) => {
    return s.charAt(0).toUpperCase() + s.slice(1)
  }

  return(
    <Card background='light-1' width='xlarge' margin={{ horizontal: 'large', vertical: 'large' }}>
      <CardHeader pad='medium' background={'light-2'}>
        {profile && (<Box flex justify='between' direction='row' align='center'>
          <Text weight='bold'>{profile.profileName} <br />
          {profile.team && profile.desc && <><Text color={'text-xweak'} size='xsmall'>{profile.desc}</Text><br /></>}
          <Text color={'text-xweak'} size='xsmall'>{profile.hkcenter}, {profile.temple}</Text></Text>
        </Box>)}
        {!profile && (<Text weight='bold'>
          {(key === 'hkcenter') ? 'Center' : capitalize(key)}: {value}
        </Text>)}
      </CardHeader>
      <CardBody pad='medium' size='large' overflow={{ horizontal: 'auto' }}>
        <Chart ref={chartRef} type='bar' data={chartjsData} />
      </CardBody>
      <CardFooter pad='medium' background='light-2'>
      </CardFooter>
    </Card>
  )
}

export default ProfileGraph