// import firebase from 'firebase/compat/app'

import { fileDownload } from './helpers'

function Console(firestore) {

  const bookCount = async (date) => {
    const dateObject = new Date(date);
    if (dateObject.toString() === 'Invalid Date') {
      console.log("INVALID DATE. Valid format: YYYY-MM-DD");
      return;
    }
    const allSktData = await firestore.collection('sankirtana').where('createdAt', '>', dateObject).get()
    let booksCount = {}
    let booksCatCount = {}
    allSktData.docs.forEach(snap => {
      const bO = snap.data().booksObject;
      const bC = snap.data().categories;
      const lang = snap.data().bookLang;

      Object.keys(bO).forEach(bN => {
        booksCount[bN + ': ' + lang] ||= 0
        booksCount[bN + ': ' + lang] += bO[bN]
      })

      Object.keys(bC).forEach(c => {
        booksCatCount[c] ||= 0
        booksCatCount[c] += bC[c]
      })
    })
    console.table(booksCount)
    console.table(booksCatCount)
  }

  const dayWiseResult = async (hkcenter, date) => {
    const dateObject = new Date(date);
    if (dateObject.toString() === 'Invalid Date') {
      console.log("INVALID DATE. Valid format: YYYY-MM-DD");
      return;
    }
    const allSktData = await firestore.collection('sankirtana').where('createdAt', '>', dateObject).get()
    let dates = {}
    allSktData.docs.forEach(snap => {
      if (snap.data().hkcenter != hkcenter) {
        return;
      }

      const date = snap.data().createdAt.toDate().toLocaleDateString('en-US');
      if (!dates[date]) {
        dates[date] = { 'Books Count': {}, 'Category Count': {}, 'Score': 0 }
      }

      const booksCount = dates[date]['Books Count'];
      const lang = snap.data().bookLang;
      const bO = snap.data().booksObject;
      Object.keys(bO).forEach(bN => {
        booksCount[bN + ' - ' + lang] ||= 0
        booksCount[bN + ' - ' + lang] += bO[bN]
      })

      const booksCatCount = dates[date]['Category Count'];
      const bC = snap.data().categories;
      Object.keys(bC).forEach(c => {
        booksCatCount[c] ||= 0
        booksCatCount[c] += bC[c]
      })

      const booksScore = dates[date]['Score'];
      const bS = snap.data().score;
      dates[date]['Score'] += bS;
    })

    const sortedDate = Object.keys(dates).sort((x, y) => {
      return new Date(x) - new Date(y)
    });

    let output = {}
    sortedDate.forEach((date) => {
      output[date] = dates[date]
    })

    console.log(JSON.stringify(output))
  }

  const bookStats = async (date) => {
    const dateObject = new Date(date);

    if (dateObject.toString() === 'Invalid Date') {
      console.log("INVALID DATE. Valid format: YYYY-MM-DD");
      return;
    }

    const allSktData = await firestore.collection('sankirtana').where('createdAt', '>', dateObject).get();

    const outputCsv = [];

    allSktData.docs.forEach(snap => {

      const data = snap.data();

      const sankirtanEntry = {
        name: data.profileName,
        uid: data.uid,
        team: !!data.team ? "TRUE" : "FALSE",
        language: data.bookLang,
        subsidized: !!data.subsidized ? "TRUE" : "FALSE",
        centre: data.hkcenter,
        createdAt: data.createdAt.toDate().toLocaleString("en-UK"),
      };

      Object.keys(data.booksObject).forEach(bookName => {
        const sankirtanEntryClone = {...sankirtanEntry}
        sankirtanEntryClone['bookName'] = bookName;
        sankirtanEntryClone['count'] = data.booksObject[bookName];
        outputCsv.push(sankirtanEntryClone);
      });
    });

    console.table(outputCsv);
    fileDownload(outputCsv, 'book-marathon-stats');
  }

  window.expensive = {
    bookCount,
    dayWiseResult,
    bookStats
  }
}

export default Console